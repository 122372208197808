type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const TooltipIcon = ({ classNames, width = 18, height = 18 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 18 18' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <g clipPath="url(#clip0_2668_4476)">
        <path
          d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
          stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 12V9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9 6H9.00833" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2668_4476">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
