type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const WhatsAppIcon = ({ classNames, width = 48, height = 49 }: Props) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M17.4976 14.3833C17.199 14.2346 15.7311 13.5159 15.4574 13.4167C15.1837 13.3176 14.9847 13.268 14.7857 13.5655C14.5867 13.863 14.0145 14.5323 13.8403 14.7306C13.6662 14.9289 13.4921 14.9538 13.1935 14.805C12.8949 14.6564 11.9329 14.3426 10.7925 13.3304C9.90484 12.5426 9.30557 11.5695 9.13135 11.2721C8.95719 10.9746 9.11278 10.8138 9.26229 10.6656C9.39652 10.5326 9.56077 10.3186 9.71005 10.1451C9.85932 9.97155 9.90907 9.84761 10.0086 9.64933C10.1081 9.451 10.0583 9.27747 9.98364 9.12874C9.90901 8.98002 9.31183 7.51754 9.06298 6.92263C8.82062 6.34326 8.57446 6.42167 8.39119 6.41259C8.21723 6.40398 8.01801 6.40217 7.81896 6.40218C7.61992 6.40219 7.2965 6.47661 7.0228 6.77403C6.74916 7.07152 5.97794 7.79048 5.97804 9.25295C5.97814 10.7155 7.04808 12.1283 7.19738 12.3266C7.34668 12.525 9.30291 15.5261 12.298 16.8129C13.0103 17.119 13.5665 17.3018 14.0001 17.4388C14.7154 17.6648 15.3662 17.6329 15.8807 17.5563C16.4543 17.471 17.6471 16.8374 17.8958 16.1433C18.1445 15.4492 18.1445 14.8543 18.0699 14.7304C17.9952 14.6064 17.7962 14.532 17.4976 14.3833ZM12.052 21.785L12.048 21.785C10.2659 21.7845 8.51796 21.3081 6.99308 20.4077L6.63039 20.1934L2.87159 21.1751L3.87466 17.5277L3.63846 17.1538C2.64421 15.5801 2.11903 13.7613 2.11965 11.8937C2.12146 6.44403 6.57645 2.01006 12.0546 2.00969C14.7073 2.01054 17.201 3.03984 19.0762 4.90792C20.9515 6.77605 21.9837 9.25934 21.9829 11.9003C21.981 17.3504 17.5261 21.7846 12.052 21.785ZM20.5024 3.48774C18.2463 1.24012 15.246 0.00184099 12.0496 0.000800307C5.46347 0.0012459 0.103531 5.3359 0.101339 11.8926C0.100621 13.9885 0.65095 16.0344 1.6967 17.8376L0.00195312 24L6.3362 22.346C8.08154 23.2932 10.0466 23.7923 12.0464 23.793L12.0513 23.793L12.0514 23.793C18.6368 23.7926 23.9972 18.4572 23.9995 11.9005C24.0005 8.723 22.7586 5.73531 20.5024 3.48774Z"
            fill="currentColor" />
    </svg>

  );
};
