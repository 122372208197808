type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const TextIcon = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.58579 1.25229C2.96086 0.877217 3.46957 0.666504 4 0.666504H9.33333C9.51014 0.666504 9.67971 0.736742 9.80474 0.861766L13.8047 4.86177C13.9298 4.98679 14 5.15636 14 5.33317V13.3332C14 13.8636 13.7893 14.3723 13.4142 14.7474C13.0391 15.1225 12.5304 15.3332 12 15.3332H4C3.46957 15.3332 2.96086 15.1225 2.58579 14.7474C2.21071 14.3723 2 13.8636 2 13.3332V2.6665C2 2.13607 2.21071 1.62736 2.58579 1.25229ZM4 1.99984C3.82319 1.99984 3.65362 2.07008 3.5286 2.1951C3.40357 2.32012 3.33333 2.48969 3.33333 2.6665V13.3332C3.33333 13.51 3.40357 13.6795 3.5286 13.8046C3.65362 13.9296 3.82319 13.9998 4 13.9998H12C12.1768 13.9998 12.3464 13.9296 12.4714 13.8046C12.5964 13.6795 12.6667 13.51 12.6667 13.3332V5.60931L9.05719 1.99984H4Z"
            fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M9.33464 0.666504C9.70283 0.666504 10.0013 0.964981 10.0013 1.33317V4.6665H13.3346C13.7028 4.6665 14.0013 4.96498 14.0013 5.33317C14.0013 5.70136 13.7028 5.99984 13.3346 5.99984H9.33464C8.96645 5.99984 8.66797 5.70136 8.66797 5.33317V1.33317C8.66797 0.964981 8.96645 0.666504 9.33464 0.666504Z"
            fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.66797 8.66667C4.66797 8.29848 4.96645 8 5.33464 8H10.668C11.0362 8 11.3346 8.29848 11.3346 8.66667C11.3346 9.03486 11.0362 9.33333 10.668 9.33333H5.33464C4.96645 9.33333 4.66797 9.03486 4.66797 8.66667Z"
            fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.66797 11.3332C4.66797 10.965 4.96645 10.6665 5.33464 10.6665H10.668C11.0362 10.6665 11.3346 10.965 11.3346 11.3332C11.3346 11.7014 11.0362 11.9998 10.668 11.9998H5.33464C4.96645 11.9998 4.66797 11.7014 4.66797 11.3332Z"
            fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.66797 6.00016C4.66797 5.63197 4.96645 5.3335 5.33464 5.3335H6.66797C7.03616 5.3335 7.33464 5.63197 7.33464 6.00016C7.33464 6.36835 7.03616 6.66683 6.66797 6.66683H5.33464C4.96645 6.66683 4.66797 6.36835 4.66797 6.00016Z"
            fill="currentColor" />
    </svg>

  );
};
