type IconProps = {
  classNames?: string
  width?: string
  height?: string
}

export const SearchIcon = (
  {
    classNames = 'fill-gray-v1',
    width = '16',
    height = '16'
  }: IconProps
) => {
  return (
    <>
      <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1466_1650)'>
          <path
            d='M15.7983 14.8543L11.2077 10.2603C12.1127 9.17499 12.657 7.78796 12.657 6.27305C12.657 2.81696 9.82068 0.00683594 6.3318 0.00683594C2.84291 0.00683594 0 2.82024 0 6.27633C0 9.73242 2.83636 12.5425 6.32524 12.5425C7.80736 12.5425 9.17143 12.0343 10.2535 11.185L14.8605 15.7921C15.1294 16.0609 15.5295 16.0609 15.7983 15.7921C16.0672 15.5232 16.0672 15.1231 15.7983 14.8543ZM1.3444 6.27633C1.3444 3.5613 3.58069 1.35451 6.32524 1.35451C9.06978 1.35451 11.3061 3.5613 11.3061 6.27633C11.3061 8.99136 9.06978 11.1981 6.32524 11.1981C3.58069 11.1981 1.3444 8.98808 1.3444 6.27633Z'
          />
        </g>
        <defs>
          <clipPath id='clip0_1466_1650'>
            <rect width={width} height={height} fill='white' />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
