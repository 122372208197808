type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const TooltipOutlineIcon = ({ classNames, width = 25, height = 25 }: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      stroke="currentColor"
      fill="currentColor"
      strokeWidth={0}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.25 11.625C4.25 6.44782 8.44782 2.25 13.625 2.25C18.8022 2.25 23 6.44782 23 11.625C23 16.8022 18.8022 21 13.625 21C8.44782 21 4.25 16.8022 4.25 11.625ZM13.625 3.75C9.27624 3.75 5.75 7.27624 5.75 11.625C5.75 15.9738 9.27624 19.5 13.625 19.5C17.9738 19.5 21.5 15.9738 21.5 11.625C21.5 7.27624 17.9738 3.75 13.625 3.75Z"
            fill="currentColor"
      />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11.5625 10.3125C11.5625 9.89829 11.8983 9.5625 12.3125 9.5625H13.8125C14.2267 9.5625 14.5625 9.89829 14.5625 10.3125V15.75C14.5625 16.1642 14.2267 16.5 13.8125 16.5C13.3983 16.5 13.0625 16.1642 13.0625 15.75V11.0625H12.3125C11.8983 11.0625 11.5625 10.7267 11.5625 10.3125Z"
            fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M11 15.9375C11 15.5233 11.3358 15.1875 11.75 15.1875H15.875C16.2892 15.1875 16.625 15.5233 16.625 15.9375C16.625 16.3517 16.2892 16.6875 15.875 16.6875H11.75C11.3358 16.6875 11 16.3517 11 15.9375Z"
            fill="currentColor" />
      <path
        d="M13.625 6.09375C13.384 6.09375 13.1483 6.16523 12.9479 6.29915C12.7475 6.43306 12.5913 6.62341 12.499 6.8461C12.4068 7.0688 12.3826 7.31385 12.4297 7.55027C12.4767 7.78668 12.5928 8.00384 12.7632 8.17429C12.9337 8.34473 13.1508 8.46081 13.3872 8.50783C13.6236 8.55486 13.8687 8.53072 14.0914 8.43848C14.3141 8.34623 14.5044 8.19002 14.6384 7.9896C14.7723 7.78918 14.8438 7.55355 14.8438 7.3125C14.8438 6.98927 14.7153 6.67927 14.4868 6.45071C14.2582 6.22215 13.9482 6.09375 13.625 6.09375Z"
        fill="currentColor" />
    </svg>
  );
};
