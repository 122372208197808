type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const TelegramIcon = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d="M13.5883 2.12061C12.6568 2.46307 1.16364 6.87677 0.993778 6.95896C0.432134 7.22472 0.366381 7.5754 0.834874 7.81376C0.881449 7.83841 1.65679 8.08499 2.56364 8.36718L4.21022 8.87951L7.95816 6.53431C10.1801 5.14252 11.7499 4.17814 11.8184 4.15896C12.0321 4.10143 12.1527 4.19458 12.0458 4.33157C12.0267 4.35348 11.3417 4.9754 10.5198 5.71239C9.16638 6.92609 6.10063 9.67951 6.04583 9.72609C6.02665 9.74252 5.96912 10.4987 5.802 12.9014L5.79378 13.0192L5.91707 13.0028C5.98556 12.9946 6.09241 12.959 6.15542 12.9233C6.22118 12.8905 6.65131 12.5014 7.11159 12.0603C7.57186 11.6165 7.96638 11.2494 7.98556 11.2439C8.00474 11.2357 8.73898 11.7562 9.61843 12.4001C10.4951 13.0439 11.2814 13.6001 11.3664 13.6384C11.5883 13.7425 11.8349 13.7507 11.9965 13.6658C12.1417 13.5891 12.2814 13.3973 12.3417 13.1973C12.3664 13.1179 12.8678 10.8001 13.4568 8.04115C14.4404 3.44937 14.5308 3.00554 14.5308 2.76718C14.5308 2.54252 14.5225 2.48773 14.4623 2.36992C14.4212 2.28499 14.3472 2.20006 14.276 2.15074C14.1143 2.03842 13.8458 2.02472 13.5883 2.12061Z"
        fill="currentColor" />
    </svg>

  );
};
