type IconProps = {
  classNames?: string
  width?: string
  height?: string
}

export const CancelIcon = (
  {
    classNames = 'fill-gray-v1',
    width = '16',
    height = '16'
  }: IconProps
) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16ZM8.99827 8L11.3227 5.67561C11.5983 5.39994 11.5983 4.953 11.3227 4.67734C11.047 4.40167 10.6001 4.40167 10.3244 4.67734L8 7.00173L5.67561 4.67734C5.39994 4.40167 4.953 4.40167 4.67734 4.67734C4.40167 4.953 4.40167 5.39994 4.67734 5.67561L7.00173 8L4.67734 10.3244C4.40167 10.6001 4.40167 11.047 4.67734 11.3227C4.953 11.5983 5.39994 11.5983 5.67561 11.3227L8 8.99827L10.3244 11.3227C10.6001 11.5983 11.047 11.5983 11.3227 11.3227C11.5983 11.047 11.5983 10.6001 11.3227 10.3244L8.99827 8Z'
            fill='#777B8A' />
    </svg>
  );

};