export const IconHeaderRequest = ({
  className,
  isSelected
}: {
  className?: string;
  isSelected?: boolean;
}) => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      // fill="none"
      fill="#09151E"
      xmlns="http://www.w3.org/2000/svg"
      className={className + ` ${isSelected && 'fill-blue-v1'}`}
    >
      <path d="M8.79235 8.34618C8.58466 8.41541 8.48081 8.65772 8.65389 8.8308L10.177 10.3885C10.35 10.5616 10.35 10.8039 10.177 10.9769L9.5885 11.5654C9.41543 11.7385 9.17312 11.7385 9.00004 11.5654L5.29619 7.82695C5.12312 7.65387 5.12312 7.41157 5.29619 7.23849L9.00004 3.53464C9.17312 3.36157 9.41543 3.36157 9.5885 3.53464L10.177 4.12311C10.35 4.29618 10.35 4.53849 10.177 4.71157L8.61927 6.26926C8.44619 6.44234 8.75773 6.75387 8.75773 6.75387C12.2885 6.85772 15.0231 9.17695 15.8539 12.327C16.7539 11.0808 17.3077 9.59234 17.3077 8.00003C17.2731 3.74234 13.5693 0.384644 9.00004 0.384644C4.43081 0.384644 0.761579 3.77695 0.761579 7.9308C0.761579 9.24618 1.14235 10.4923 1.76543 11.6C1.86927 11.7731 1.90389 11.9808 1.83466 12.1885L0.761579 15.1308C0.657733 15.4077 0.934656 15.65 1.21158 15.5808L4.22312 14.4385C4.39619 14.3693 4.60389 14.4039 4.81158 14.5077C6.05773 15.2 7.54619 15.6154 9.1385 15.6154C11.1808 15.5808 13.0847 14.8885 14.5039 13.7116C14.1231 10.7693 11.7347 8.48464 8.79235 8.34618Z" />
    </svg>
  );
};
