type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const IconPhone = ({ classNames, width = 24, height = 24 }: Props) => {
  return (
    <svg width={width} height={height} viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'
         className={classNames}>
      <path
        d='M28.3235 32.4996C27.4085 32.4996 26.1232 32.1686 24.1985 31.0933C21.8581 29.7808 20.0477 28.5691 17.7199 26.2474C15.4756 24.0044 14.3834 22.5522 12.8548 19.7707C11.1279 16.63 11.4223 14.9838 11.7513 14.2802C12.1432 13.4393 12.7216 12.9363 13.4693 12.4371C13.894 12.1588 14.3434 11.9203 14.8118 11.7246C14.8587 11.7044 14.9023 11.6852 14.9412 11.6678C15.1732 11.5633 15.5248 11.4053 15.9701 11.5741C16.2673 11.6857 16.5326 11.9139 16.9479 12.3241C17.7996 13.1641 18.9635 15.0349 19.3929 15.9536C19.6812 16.5728 19.872 16.9816 19.8724 17.44C19.8724 17.9768 19.6024 18.3907 19.2748 18.8374C19.2134 18.9213 19.1524 19.0014 19.0934 19.0793C18.7366 19.548 18.6584 19.6835 18.7099 19.9253C18.8145 20.4114 19.594 21.8585 20.8751 23.1368C22.1562 24.415 23.5615 25.1453 24.0495 25.2494C24.3016 25.3033 24.4399 25.2218 24.9237 24.8524C24.9931 24.7994 25.0643 24.7446 25.1388 24.6897C25.6385 24.318 26.0332 24.055 26.5573 24.055H26.5601C27.0162 24.055 27.4066 24.2528 28.0535 24.5791C28.8973 25.0047 30.8243 26.1536 31.6695 27.0063C32.0806 27.4207 32.3098 27.685 32.4218 27.9818C32.5906 28.4285 32.4316 28.7786 32.3281 29.013C32.3107 29.0519 32.2915 29.0946 32.2713 29.1419C32.074 29.6095 31.8341 30.058 31.5546 30.4816C31.0563 31.2269 30.5515 31.8039 29.7087 32.1963C29.2759 32.401 28.8023 32.5047 28.3235 32.4996Z'
        fill='currentColor' />
    </svg>
  );
};
