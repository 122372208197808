type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const ArrowSpinnerIcon = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d="M6.87951 3.29051C7.73047 3.08834 8.62038 3.12124 9.45409 3.38568C10.2878 3.65012 11.034 4.13616 11.6128 4.79185C11.7593 4.95734 11.9656 5.05787 12.1862 5.07131C12.4068 5.08475 12.6237 5.01 12.7892 4.86351C12.9547 4.71702 13.0552 4.51079 13.0686 4.29019C13.0821 4.06958 13.0073 3.85267 12.8608 3.68718C12.0825 2.80525 11.0791 2.15138 9.95797 1.79548C8.83682 1.43957 7.64003 1.39499 6.49551 1.66651C5.33401 1.95813 4.26933 2.54932 3.40776 3.38106C2.54618 4.21281 1.91786 5.25601 1.58551 6.40651C1.57453 6.44461 1.55028 6.47751 1.51714 6.49928C1.484 6.52105 1.44417 6.53023 1.40484 6.52518L0.716177 6.43851C0.655105 6.43066 0.593058 6.4402 0.537161 6.46603C0.481264 6.49185 0.433785 6.53292 0.400177 6.58451C0.36567 6.636 0.346205 6.6961 0.343967 6.75804C0.34173 6.81999 0.356809 6.88133 0.387511 6.93518L2.03818 9.82985C2.06392 9.87484 2.09979 9.91322 2.14296 9.94192C2.18613 9.97062 2.23539 9.98885 2.28684 9.99518C2.3006 9.99617 2.31442 9.99617 2.32818 9.99518C2.37282 9.99518 2.41701 9.98621 2.45813 9.9688C2.49924 9.95139 2.53644 9.9259 2.56751 9.89385L4.88551 7.49851C4.92866 7.45389 4.95848 7.39811 4.97164 7.33745C4.98479 7.27679 4.98074 7.21366 4.95995 7.15517C4.93917 7.09669 4.90246 7.04517 4.85397 7.00642C4.80548 6.96767 4.74714 6.94323 4.68551 6.93585L3.43351 6.78118C3.40894 6.77771 3.38545 6.7688 3.36476 6.75509C3.34407 6.74139 3.3267 6.72323 3.31393 6.70196C3.30115 6.68069 3.29328 6.65683 3.29091 6.63212C3.28853 6.60742 3.29169 6.5825 3.30018 6.55918C3.58141 5.76168 4.0553 5.046 4.67974 4.47576C5.30418 3.90552 6.05982 3.49838 6.87951 3.29051Z"
        fill="currentColor">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="360 8 8"
          to="0 8 8"
          dur="2s"
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M15.6042 9.39985C15.6383 9.3481 15.6573 9.28788 15.659 9.22593C15.6608 9.16399 15.6453 9.10278 15.6142 9.04918L13.9475 6.16318C13.9214 6.11839 13.8852 6.0803 13.8418 6.05195C13.7984 6.0236 13.749 6.00576 13.6975 5.99985C13.6461 5.99309 13.5938 5.9987 13.545 6.01621C13.4962 6.03372 13.4522 6.06262 13.4168 6.10051L11.1128 8.50985C11.0701 8.55451 11.0406 8.61019 11.0277 8.67065C11.0147 8.73111 11.0189 8.79398 11.0396 8.85223C11.0604 8.91047 11.0969 8.96179 11.1452 9.00046C11.1934 9.03913 11.2515 9.06361 11.3128 9.07118L12.5448 9.21851C12.5697 9.22145 12.5937 9.23 12.6148 9.24351C12.6359 9.25702 12.6537 9.27514 12.6668 9.29651C12.6799 9.31787 12.6881 9.34188 12.6908 9.36678C12.6934 9.39169 12.6905 9.41688 12.6822 9.44051C12.4013 10.238 11.9275 10.9536 11.303 11.5236C10.6784 12.0935 9.92262 12.5002 9.10284 12.7072C8.25181 12.909 7.36196 12.8758 6.5283 12.6113C5.69463 12.3467 4.94848 11.8607 4.36951 11.2052C4.29786 11.1203 4.20998 11.0507 4.11103 11.0003C4.01208 10.9499 3.90406 10.9198 3.79331 10.9117C3.68256 10.9037 3.57132 10.9178 3.46612 10.9534C3.36092 10.9889 3.26389 11.0451 3.18072 11.1187C3.09755 11.1923 3.02992 11.2817 2.98181 11.3818C2.9337 11.4819 2.90607 11.5906 2.90056 11.7015C2.89504 11.8124 2.91175 11.9233 2.94969 12.0276C2.98763 12.132 3.04605 12.2277 3.12151 12.3092C3.90013 13.1909 4.90366 13.8446 6.02489 14.2004C7.14613 14.5562 8.34295 14.6007 9.48751 14.3292C10.6505 14.0379 11.7165 13.446 12.5788 12.613C13.441 11.7799 14.0691 10.7348 14.4002 9.58251C14.4113 9.54457 14.4354 9.51176 14.4683 9.48992C14.5013 9.46808 14.5409 9.4586 14.5802 9.46318L15.2855 9.54785C15.2988 9.54879 15.3122 9.54879 15.3255 9.54785C15.3806 9.54811 15.4348 9.53473 15.4835 9.50891C15.5321 9.48308 15.5736 9.44561 15.6042 9.39985Z"
        fill="currentColor">
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="360 8 8"
          to="0 8 8"
          dur="2s"
          repeatCount="indefinite"
        />
      </path>
    </svg>

  );
};
