type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const ChainLinkIcon = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M12.7753 2.75071C13.907 1.65776 15.4226 1.053 16.9958 1.06667C18.5689 1.08034 20.0738 1.71135 21.1863 2.8238C22.2987 3.93625 22.9297 5.44112 22.9434 7.0143C22.9571 8.58748 22.3523 10.1031 21.2593 11.2347L21.2472 11.2471L18.2473 14.247C17.639 14.8555 16.907 15.326 16.1009 15.6267C15.2948 15.9274 14.4334 16.0512 13.5753 15.9898C12.7171 15.9283 11.8822 15.683 11.1272 15.2705C10.3721 14.858 9.71464 14.2879 9.19929 13.599C8.86849 13.1567 8.95883 12.53 9.40108 12.1992C9.84333 11.8684 10.47 11.9588 10.8008 12.401C11.1444 12.8603 11.5827 13.2404 12.0861 13.5154C12.5894 13.7904 13.146 13.9539 13.7181 13.9949C14.2903 14.0359 14.8645 13.9533 15.4019 13.7528C15.9393 13.5524 16.4273 13.2387 16.8328 12.833L16.833 12.8329L19.8265 9.83938C20.5515 9.08561 20.9526 8.07773 20.9435 7.03168C20.9344 5.9829 20.5137 4.97965 19.772 4.23801C19.0304 3.49638 18.0272 3.07571 16.9784 3.06659C15.9319 3.0575 14.9236 3.4589 14.1697 4.18454L12.4551 5.88916C12.0634 6.27855 11.4303 6.2767 11.0409 5.88504C10.6515 5.49338 10.6534 4.86022 11.045 4.47083L12.765 2.76083L12.7753 2.75071Z" />
      <path
        fill="currentColor"
        d="M7.89911 8.37328C8.70522 8.07258 9.56657 7.94876 10.4247 8.01022C11.2829 8.07168 12.1178 8.31699 12.8729 8.72949C13.6279 9.142 14.2854 9.71207 14.8007 10.401C15.1315 10.8433 15.0412 11.47 14.5989 11.8008C14.1567 12.1316 13.53 12.0412 13.1992 11.599C12.8556 11.1397 12.4173 10.7596 11.9139 10.4846C11.4106 10.2096 10.854 10.0461 10.2819 10.0051C9.70976 9.96414 9.13552 10.0467 8.59811 10.2472C8.06071 10.4476 7.5727 10.7613 7.16719 11.167L4.17355 14.1606C3.44851 14.9144 3.04746 15.9223 3.05655 16.9683C3.06566 18.0171 3.48634 19.0204 4.22797 19.762C4.9696 20.5036 5.97285 20.9243 7.02164 20.9334C8.06768 20.9425 9.07555 20.5415 9.82932 19.8164L11.5328 18.1129C11.9234 17.7224 12.5565 17.7224 12.9471 18.1129C13.3376 18.5034 13.3376 19.1366 12.9471 19.5271L11.2371 21.2371L11.2247 21.2493C10.0931 22.3422 8.57744 22.947 7.00426 22.9333C5.43108 22.9197 3.9262 22.2886 2.81376 21.1762C1.70131 20.0638 1.07029 18.5589 1.05662 16.9857C1.04295 15.4125 1.64772 13.8969 2.74067 12.7653L2.75285 12.7529L5.75272 9.75302C5.75268 9.75306 5.75276 9.75298 5.75272 9.75302C6.36096 9.1446 7.09305 8.67397 7.89911 8.37328Z" />
    </svg>
  );
};
