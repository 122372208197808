type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const PhoneIcon = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d="M1.60156 2.4001C1.60156 2.18792 1.68585 1.98444 1.83588 1.83441C1.98591 1.68438 2.18939 1.6001 2.40156 1.6001H4.12396C4.31333 1.60018 4.49652 1.66744 4.64096 1.78991C4.78539 1.91237 4.88171 2.0821 4.91276 2.2689L5.50476 5.8169C5.53305 5.98587 5.50634 6.15946 5.42857 6.31212C5.3508 6.46478 5.22608 6.58844 5.07276 6.6649L3.83436 7.2833C4.27845 8.38382 4.93983 9.38352 5.77898 10.2227C6.61814 11.0618 7.61784 11.7232 8.71836 12.1673L9.33756 10.9289C9.41398 10.7757 9.53751 10.6511 9.69001 10.5733C9.8425 10.4956 10.0159 10.4688 10.1848 10.4969L13.7328 11.0889C13.9196 11.12 14.0893 11.2163 14.2118 11.3607C14.3342 11.5051 14.4015 11.6883 14.4016 11.8777V13.6001C14.4016 13.8123 14.3173 14.0158 14.1672 14.1658C14.0172 14.3158 13.8137 14.4001 13.6016 14.4001H12.0016C6.25756 14.4001 1.60156 9.7441 1.60156 4.0001V2.4001Z"
        fill="currentColor" />
      <path
        d="M13.3645 2.63462C13.5145 2.78464 13.5987 2.98809 13.5987 3.20022C13.5987 3.41235 13.5145 3.6158 13.3645 3.76582L12.3301 4.80022L13.3645 5.83462C13.5102 5.9855 13.5909 6.18758 13.589 6.39734C13.5872 6.6071 13.5031 6.80775 13.3547 6.95607C13.2064 7.1044 13.0058 7.18854 12.796 7.19036C12.5863 7.19218 12.3842 7.11155 12.2333 6.96582L11.1989 5.93142L10.1645 6.96582C10.0907 7.04223 10.0024 7.10317 9.90482 7.1451C9.80721 7.18703 9.70224 7.2091 9.59601 7.21002C9.48979 7.21094 9.38445 7.1907 9.28613 7.15048C9.18781 7.11025 9.09849 7.05085 9.02338 6.97574C8.94826 6.90062 8.88886 6.8113 8.84864 6.71298C8.80841 6.61467 8.78817 6.50932 8.78909 6.4031C8.79002 6.29688 8.81208 6.1919 8.85401 6.0943C8.89594 5.99669 8.95689 5.90842 9.03329 5.83462L10.0677 4.80022L9.03329 3.76582C8.88757 3.61494 8.80693 3.41286 8.80875 3.2031C8.81058 2.99334 8.89471 2.79269 9.04304 2.64436C9.19137 2.49604 9.39202 2.4119 9.60177 2.41008C9.81153 2.40826 10.0136 2.48889 10.1645 2.63462L11.1989 3.66902L12.2333 2.63462C12.3833 2.48464 12.5868 2.40039 12.7989 2.40039C13.011 2.40039 13.2145 2.48464 13.3645 2.63462Z"
        fill="currentColor" />
    </svg>

  );
};
