type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const WhatsAppLandingIcon = ({ classNames, width = 48, height = 49 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 48 49' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d="M0.199219 16.1C0.199219 10.4051 0.199219 7.55764 1.32508 5.39026C2.27384 3.56382 3.76304 2.07462 5.58947 1.12587C7.75686 0 10.6043 0 16.2992 0H32.0992C37.7941 0 40.6416 0 42.809 1.12587C44.6354 2.07462 46.1246 3.56382 47.0734 5.39026C48.1992 7.55764 48.1992 10.4051 48.1992 16.1V31.9C48.1992 37.5949 48.1992 40.4424 47.0734 42.6097C46.1246 44.4362 44.6354 45.9254 42.809 46.8741C40.6416 48 37.7941 48 32.0992 48H16.2992C10.6043 48 7.75686 48 5.58947 46.8741C3.76304 45.9254 2.27384 44.4362 1.32508 42.6097C0.199219 40.4424 0.199219 37.5949 0.199219 31.9V16.1Z"
        fill="url(#paint0_linear_2_2764)" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M25.5924 6.59338C29.8411 6.80219 33.8001 8.55424 36.8239 11.5801C40.0494 14.8075 41.8247 19.0977 41.823 23.66C41.8191 33.0749 34.1547 40.7353 24.7392 40.7353H24.7322C21.8729 40.7343 19.0635 40.0173 16.5682 38.6569L7.51172 41.0314L9.93543 32.183C8.44041 29.5935 7.65373 26.6561 7.65497 23.6465C7.65875 14.2319 15.3226 6.57227 24.739 6.57227L25.5924 6.59338ZM16.9877 35.5657L17.5063 35.8732C19.6864 37.1663 22.1854 37.8505 24.7334 37.8515H24.7391C32.5655 37.8515 38.9353 31.4847 38.9385 23.6591C38.94 19.8669 37.4644 16.3011 34.7835 13.6185C32.1026 10.9358 28.5375 9.4575 24.7448 9.45626C16.9124 9.45626 10.5425 15.8224 10.5394 23.6475C10.5383 26.3292 11.289 28.9409 12.7104 31.2005L13.048 31.7375L11.6136 36.9747L16.9877 35.5657ZM33.3459 27.7205C33.2392 27.5424 32.9547 27.4357 32.5278 27.2221C32.1009 27.0086 30.0023 25.9764 29.611 25.8339C29.2197 25.6917 28.9351 25.6206 28.6506 26.0476C28.366 26.4746 27.548 27.4357 27.2989 27.7205C27.0499 28.0052 26.8009 28.0409 26.3741 27.8273C25.9473 27.6138 24.5718 27.1632 22.9414 25.7096C21.6723 24.5782 20.8156 23.1811 20.5666 22.754C20.3177 22.3269 20.5401 22.0959 20.7538 21.8833C20.9458 21.692 21.1807 21.3849 21.3941 21.1358C21.6076 20.8867 21.6787 20.7086 21.821 20.424C21.9633 20.1392 21.8921 19.8901 21.7854 19.6765C21.6787 19.4629 20.825 17.3628 20.4693 16.5085C20.1227 15.6766 19.7708 15.7892 19.5088 15.7761C19.2601 15.7638 18.9753 15.7611 18.6908 15.7611C18.4061 15.7611 17.9437 15.8678 17.5524 16.295C17.1611 16.7221 16.0584 17.7544 16.0584 19.8543C16.0584 21.9545 17.588 23.9831 17.8014 24.2679C18.0149 24.5528 20.8114 28.8621 25.0935 30.7103C26.112 31.1499 26.9071 31.4124 27.527 31.609C28.5497 31.9338 29.4801 31.888 30.2157 31.7781C31.0358 31.6556 32.7412 30.746 33.0969 29.7495C33.4526 28.7526 33.4526 27.8984 33.3459 27.7205Z"
            fill="white" />
      <defs>
        <linearGradient id="paint0_linear_2_2764" x1="24.1993" y1="48" x2="24.1993" y2="0"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#25CF43" />
          <stop offset="1" stopColor="#61FD7D" />
        </linearGradient>
      </defs>
    </svg>

  );
};
