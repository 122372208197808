type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const UrlIcon = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M4.5 5.75C3.90326 5.75 3.33097 5.98705 2.90901 6.40901C2.48705 6.83097 2.25 7.40326 2.25 8C2.25 8.59674 2.48705 9.16903 2.90901 9.59099C3.33097 10.0129 3.90326 10.25 4.5 10.25H7.02063V11.75H4.5C3.50544 11.75 2.55161 11.3549 1.84835 10.6517C1.14509 9.94839 0.75 8.99456 0.75 8C0.75 7.00544 1.14509 6.05161 1.84835 5.34835C2.55161 4.64509 3.50544 4.25 4.5 4.25H6.98156V5.75H4.5ZM9.01844 4.25H11.5C12.4946 4.25 13.4484 4.64509 14.1517 5.34835C14.8549 6.05161 15.25 7.00544 15.25 8C15.25 8.99456 14.8549 9.94839 14.1517 10.6517C13.4484 11.3549 12.4946 11.75 11.5 11.75H8.97938V10.25H11.5C12.0967 10.25 12.669 10.0129 13.091 9.59099C13.5129 9.16903 13.75 8.59674 13.75 8C13.75 7.40326 13.5129 6.83097 13.091 6.40901C12.669 5.98705 12.0967 5.75 11.5 5.75H9.01844V4.25ZM4.53344 7.25H11.5291V8.75H4.53344V7.25Z"
            fill="currentColor" />
    </svg>

  );
};
