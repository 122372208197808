type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const TelegramLandingIcon = ({ classNames, width = 48, height = 49 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 48 49' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <g clipPath="url(#clip0_2_2768)">
        <path
          d="M0.400391 16.1C0.400391 10.4051 0.400391 7.55764 1.52626 5.39026C2.47501 3.56382 3.96421 2.07462 5.79065 1.12587C7.95803 0 10.8055 0 16.5004 0H32.3004C37.9953 0 40.8428 0 43.0101 1.12587C44.8366 2.07462 46.3258 3.56382 47.2745 5.39026C48.4004 7.55764 48.4004 10.4051 48.4004 16.1V31.9C48.4004 37.5949 48.4004 40.4424 47.2745 42.6097C46.3258 44.4362 44.8366 45.9254 43.0101 46.8741C40.8428 48 37.9953 48 32.3004 48H16.5004C10.8055 48 7.95803 48 5.79065 46.8741C3.96421 45.9254 2.47501 44.4362 1.52626 42.6097C0.400391 40.4424 0.400391 37.5949 0.400391 31.9V16.1Z"
          fill="url(#paint0_linear_2_2768)" />
        <path d="M18.9023 36.748C17.9304 36.748 18.0956 36.3811 17.7604 35.4557L14.9023 26.0495L36.9023 12.998"
              fill="#C8DAEA" />
        <path d="M18.8984 36.7498C19.6484 36.7498 19.9798 36.4068 20.3984 35.9998L24.3984 32.1103L19.4089 29.1016"
              fill="#A9C9DD" />
        <path
          d="M19.4092 29.1043L31.4992 38.0366C32.8788 38.7978 33.8746 38.4037 34.2182 36.7557L39.1395 13.5649C39.6433 11.5449 38.3694 10.6287 37.0496 11.2279L8.15212 22.3706C6.17959 23.1618 6.19109 24.2623 7.79256 24.7526L15.2083 27.0672L32.3766 16.236C33.187 15.7445 33.9309 16.0087 33.3204 16.5506"
          fill="url(#paint1_linear_2_2768)" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_2_2768" x1="32.402" y1="8.0016" x2="20.402" y2="36"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#37AEE2" />
          <stop offset="1" stopColor="#1E96C8" />
        </linearGradient>
        <linearGradient id="paint1_linear_2_2768" x1="28.1504" y1="23.0015" x2="32.8452" y2="33.6847"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFF7FC" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_2_2768">
          <rect width="48" height="48" fill="white" transform="translate(0.400391)" />
        </clipPath>
      </defs>
    </svg>

  );
};
