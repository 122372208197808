type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const AnalyticsIcon = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z"
        fill="currentColor" />
      <path d="M7 12H9V17H7V12ZM15 7H17V17H15V7ZM11 14H13V17H11V14ZM11 10H13V12H11V10Z" fill="currentColor" />
    </svg>
  );
};
