type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const PlayIcon = ({ classNames, width = 10, height = 10 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 10 10' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d="M9.11563 4.55564C9.49084 4.77172 9.49084 5.31316 9.11563 5.52925L1.53411 9.89532C1.1596 10.111 0.692012 9.84068 0.692012 9.40851L0.692013 0.676374C0.692013 0.244211 1.1596 -0.0260977 1.53411 0.189572L9.11563 4.55564Z"
        fill="currentColor"></path>
    </svg>

  );
};
