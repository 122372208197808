type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const KygLogo = ({ classNames, width = 31, height = 20 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames}
         stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 31 20" aria-hidden="true"
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d="M30.1545 0.831588C30.1659 0.55944 29.798 0.378751 29.6322 0.597362C27.795 3.0177 22.0675 4.90266 19.6442 4.76212H11.9366C11.5755 4.76212 11.2189 4.78889 10.8715 4.83797C4.92597 2.45779 1.38321 3.61107 0.147784 4.34721C-0.0884006 4.48774 -0.0293545 4.83574 0.238624 4.90266C3.34536 5.68564 5.25754 7.72007 5.63226 8.1149C4.86011 9.27488 4.41046 10.6624 4.41046 12.1525C4.41046 16.2347 7.78062 19.5451 11.9366 19.5451H21.2613C25.4172 19.5451 28.7874 16.2347 28.7874 12.1525C28.7874 10.5799 28.2878 9.12542 27.4361 7.92752C29.9388 4.95843 30.1069 1.92464 30.1523 0.829357L30.1545 0.831588ZM11.3688 13.366C10.5967 13.366 9.97214 12.7526 9.97214 11.9941C9.97214 11.2357 10.5967 10.6222 11.3688 10.6222C12.1409 10.6222 12.7655 11.2357 12.7655 11.9941C12.7655 12.7526 12.1409 13.366 11.3688 13.366ZM16.7193 13.366C15.9471 13.366 15.3226 12.7526 15.3226 11.9941C15.3226 11.2357 15.9471 10.6222 16.7193 10.6222C17.4914 10.6222 18.116 11.2357 18.116 11.9941C18.116 12.7526 17.4914 13.366 16.7193 13.366ZM22.0675 13.366C21.2954 13.366 20.6708 12.7526 20.6708 11.9941C20.6708 11.2357 21.2954 10.6222 22.0675 10.6222C22.8396 10.6222 23.4642 11.2357 23.4642 11.9941C23.4642 12.7526 22.8396 13.366 22.0675 13.366Z"
      />
    </svg>

  );
};
