type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const ArrowRight = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox="0 0 18 19" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M3.75 9.36914H14.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9 4.11914L14.25 9.36914L9 14.6191" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
            strokeLinejoin="round" />

    </svg>
  );
};
