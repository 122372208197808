type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const LandingArrowDown = ({ classNames, width = 35, height = 45 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox="0 0 35 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4717 0.762265C14.3404 0.22582 13.7991 -0.1026 13.2626 0.0287184C12.7262 0.160037 12.3977 0.701367 12.5291 1.23781L14.4717 0.762265ZM3.06881 43.4996C3.24094 44.0244 3.80589 44.3103 4.33067 44.1382L12.8824 41.3333C13.4072 41.1611 13.6931 40.5962 13.521 40.0714C13.3488 39.5466 12.7839 39.2608 12.2591 39.4329L4.65755 41.9261L2.16431 34.3245C1.99219 33.7998 1.42724 33.5139 0.902463 33.686C0.377685 33.8581 0.0918002 34.4231 0.263923 34.9479L3.06881 43.4996ZM12.5291 1.23781C15.1411 11.9081 17.6778 19.222 20.0446 24.0055C21.228 26.3972 22.3852 28.1899 23.5134 29.4592C24.6364 30.7226 25.7855 31.5283 26.9611 31.8256C28.1771 32.1332 29.3017 31.8661 30.2571 31.22C31.183 30.5938 31.9342 29.6268 32.5315 28.5224C33.7269 26.3124 34.447 23.2902 34.6311 20.3603C34.8143 17.4457 34.4781 14.4442 33.3962 12.3194C32.849 11.2445 32.0743 10.3244 31.0095 9.80881C29.9252 9.28371 28.6722 9.24222 27.3125 9.68675C24.6775 10.5482 21.4624 13.2877 17.5461 18.4498C13.5971 23.6549 8.82728 31.4714 3.12675 42.7364L4.91127 43.6395C10.5843 32.4288 15.292 24.7298 19.1394 19.6586C23.0195 14.5442 25.9197 12.2462 27.9339 11.5877C28.8988 11.2723 29.6039 11.3503 30.1379 11.6089C30.6915 11.8769 31.1954 12.4047 31.614 13.2268C32.4638 14.8959 32.8079 17.4845 32.6351 20.2349C32.4632 22.97 31.7909 25.6877 30.7724 27.5709C30.2628 28.5132 29.7017 29.1812 29.1367 29.5633C28.6012 29.9254 28.0565 30.0397 27.4515 29.8867C26.8062 29.7235 25.9826 29.2267 25.0082 28.1304C24.0389 27.04 22.9739 25.416 21.8372 23.1185C19.5637 18.5237 17.0685 11.3702 14.4717 0.762265L12.5291 1.23781Z"
        fill="currentColor" />
    </svg>

  );
};
