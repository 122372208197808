type Props = {
  classNames?: string
}
export const CheckIcon = (props: Props) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.classNames}
    >
      <path
        d="M12.4681 0.254957L4.50052 7.90368L1.53194 5.02125C1.1801 4.6813 0.615707 4.6813 0.263874 5.02125C-0.0879581 5.36119 -0.0879581 5.90652 0.263874 6.24646L3.86283 9.74504C4.03141 9.90793 4.25864 10 4.50052 10C4.73508 10 4.9623 9.90793 5.13089 9.74504L13.7361 1.48017C14.088 1.14023 14.088 0.594901 13.7361 0.254957C13.3843 -0.0849858 12.8199 -0.0849858 12.4681 0.254957Z"
        fill="currentColor"
      />
    </svg>
  );
};
