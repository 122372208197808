type IconProps = {
  classNames?: string
  width?: string
  height?: string
}

export const EditIcon = (
  {
    classNames = 'fill-gray-v1',
    width = '16',
    height = '16'
  }: IconProps
) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.9636 1.03662C13.5863 -0.344074 11.3466 -0.344074 9.96928 1.03662L0.812414 10.1901C0.740823 10.2617 0.696504 10.3537 0.682868 10.4526L0.00445532 15.4776C-0.0159993 15.6208 0.0351373 15.764 0.134001 15.8628C0.219229 15.9481 0.338548 15.9992 0.457867 15.9992C0.478321 15.9992 0.498776 15.9992 0.519231 15.9958L3.54652 15.5867C3.79879 15.5526 3.97607 15.3208 3.94197 15.0685C3.90788 14.8162 3.67606 14.639 3.42379 14.673L0.996506 15.0003L1.47037 11.4958L5.15903 15.1844C5.24425 15.2696 5.36357 15.3208 5.48289 15.3208C5.60221 15.3208 5.72153 15.2731 5.80676 15.1844L14.9636 6.03096C15.6318 5.36277 16 4.47641 16 3.53208C16 2.58776 15.6318 1.70139 14.9636 1.03662ZM10.1466 2.16162L11.6841 3.69913L3.32834 12.0549L1.79083 10.5173L10.1466 2.16162ZM5.4863 14.2094L3.98288 12.706L12.3386 4.35027L13.842 5.85369L5.4863 14.2094ZM14.4829 5.19573L10.8045 1.5173C11.2716 1.13207 11.8545 0.920706 12.4682 0.920706C13.167 0.920706 13.8216 1.19343 14.3159 1.68435C14.8102 2.17526 15.0795 2.83322 15.0795 3.53208C15.0795 4.14913 14.8682 4.72868 14.4829 5.19573Z'
      />
    </svg>
  );
};
