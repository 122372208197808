type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const EmailIcon = ({ classNames, width = 24, height = 24 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 20 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M0.878679 0.87868C1.44129 0.31607 2.20435 0 3 0H17C17.7957 0 18.5587 0.31607 19.1213 0.87868C19.6839 1.44129 20 2.20435 20 3V3.98448C20.0001 3.99422 20.0001 4.00395 20 4.01368V13C20 13.7957 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7957 16 17 16H3C2.20435 16 1.44129 15.6839 0.878679 15.1213C0.31607 14.5587 -5.06618e-07 13.7956 -5.06618e-07 13V4.01367C-0.000142127 4.00395 -0.000142366 3.99422 -5.06618e-07 3.98449V3C-5.06618e-07 2.20435 0.31607 1.44129 0.878679 0.87868ZM2 5.86852V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V5.86852L11.665 10.0919C11.6649 10.0919 11.6648 10.092 11.6647 10.0921C11.1718 10.4208 10.5925 10.5963 10 10.5963C9.40748 10.5963 8.82823 10.4208 8.3353 10.0921C8.3352 10.092 8.3351 10.0919 8.335 10.0919L2 5.86852ZM18 3.46482L10.5553 8.42795L10.555 8.42815C10.3907 8.53779 10.1975 8.5963 10 8.5963C9.80245 8.5963 9.60933 8.53779 9.445 8.42815L9.4447 8.42795L2 3.46482V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V3.46482Z'
            fill='currentColor' />
    </svg>
  );
};