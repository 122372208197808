type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const CloseIcon = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3258 10L15.0367 6.28906C15.2128 6.11325 15.3119 5.87468 15.3121 5.62583C15.3123 5.37698 15.2137 5.13823 15.0379 4.96211C14.8621 4.78599 14.6235 4.68692 14.3746 4.6867C14.1258 4.68648 13.887 4.78513 13.7109 4.96094L9.99998 8.67187L6.28904 4.96094C6.11292 4.78482 5.87405 4.68587 5.62498 4.68587C5.37591 4.68587 5.13704 4.78482 4.96092 4.96094C4.7848 5.13706 4.68585 5.37593 4.68585 5.625C4.68585 5.87407 4.7848 6.11294 4.96092 6.28906L8.67185 10L4.96092 13.7109C4.7848 13.8871 4.68585 14.1259 4.68585 14.375C4.68585 14.6241 4.7848 14.8629 4.96092 15.0391C5.13704 15.2152 5.37591 15.3141 5.62498 15.3141C5.87405 15.3141 6.11292 15.2152 6.28904 15.0391L9.99998 11.3281L13.7109 15.0391C13.887 15.2152 14.1259 15.3141 14.375 15.3141C14.6241 15.3141 14.8629 15.2152 15.039 15.0391C15.2152 14.8629 15.3141 14.6241 15.3141 14.375C15.3141 14.1259 15.2152 13.8871 15.039 13.7109L11.3258 10Z"
        fill="currentColor"
      />
    </svg>
  );
};
