import { ComponentProps, FC } from 'react';

type Props = {
  classNames?: string
  width?: number | string
  height?: number | string
}

export const DocumentIcon: FC<ComponentProps<'svg'>> = ({ classNames, width = 24, height = 24 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'
         fill='none'>
      <path
        d='M8.25 10.5C8.05109 10.5 7.86032 10.579 7.71967 10.7197C7.57902 10.8603 7.5 11.0511 7.5 11.25C7.5 11.4489 7.57902 11.6397 7.71967 11.7803C7.86032 11.921 8.05109 12 8.25 12H15.75C15.9489 12 16.1397 11.921 16.2803 11.7803C16.421 11.6397 16.5 11.4489 16.5 11.25C16.5 11.0511 16.421 10.8603 16.2803 10.7197C16.1397 10.579 15.9489 10.5 15.75 10.5H8.25ZM7.5 14.25C7.5 14.0511 7.57902 13.8603 7.71967 13.7197C7.86032 13.579 8.05109 13.5 8.25 13.5H15.75C15.9489 13.5 16.1397 13.579 16.2803 13.7197C16.421 13.8603 16.5 14.0511 16.5 14.25C16.5 14.4489 16.421 14.6397 16.2803 14.7803C16.1397 14.921 15.9489 15 15.75 15H8.25C8.05109 15 7.86032 14.921 7.71967 14.7803C7.57902 14.6397 7.5 14.4489 7.5 14.25ZM7.5 17.25C7.5 17.0511 7.57902 16.8603 7.71967 16.7197C7.86032 16.579 8.05109 16.5 8.25 16.5H11.25C11.4489 16.5 11.6397 16.579 11.7803 16.7197C11.921 16.8603 12 17.0511 12 17.25C12 17.4489 11.921 17.6397 11.7803 17.7803C11.6397 17.921 11.4489 18 11.25 18H8.25C8.05109 18 7.86032 17.921 7.71967 17.7803C7.57902 17.6397 7.5 17.4489 7.5 17.25Z'
        fill='currentColor' />
      <path
        d='M14.25 0H6C5.20435 0 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3V21C3 21.7956 3.31607 22.5587 3.87868 23.1213C4.44129 23.6839 5.20435 24 6 24H18C18.7956 24 19.5587 23.6839 20.1213 23.1213C20.6839 22.5587 21 21.7956 21 21V6.75L14.25 0ZM14.25 1.5V4.5C14.25 5.09674 14.4871 5.66903 14.909 6.09099C15.331 6.51295 15.9033 6.75 16.5 6.75H19.5V21C19.5 21.3978 19.342 21.7794 19.0607 22.0607C18.7794 22.342 18.3978 22.5 18 22.5H6C5.60218 22.5 5.22064 22.342 4.93934 22.0607C4.65804 21.7794 4.5 21.3978 4.5 21V3C4.5 2.60218 4.65804 2.22064 4.93934 1.93934C5.22064 1.65804 5.60218 1.5 6 1.5H14.25Z'
        fill='currentColor' />
    </svg>
  );
};
