export const IconHeaderUsers = ({
  className,
  isSelected
}: {
  className?: string;
  isSelected?: boolean;
}) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#09151E"
      xmlns="http://www.w3.org/2000/svg"
      className={className + ` ${isSelected && 'fill-blue-v1'}`}
    >
      <path d="M10.8305 7.59186C12.6356 7.59186 14.1125 6.12247 14.1125 4.32656C14.1125 2.53065 12.6356 1.06128 10.8305 1.06128C10.7485 1.06128 10.6665 1.06128 10.5844 1.06128C11.0767 1.8776 11.4048 2.85719 11.4048 3.83678C11.4048 5.22452 10.8305 6.53066 9.92798 7.51026C10.1741 7.59186 10.5023 7.59186 10.8305 7.59186Z" />
      <path d="M12.2255 9.30603H11.405C12.6357 10.3672 13.4563 11.9999 13.4563 13.7958C13.4563 14.204 13.3742 14.5305 13.2921 14.857C14.3589 14.6938 15.0152 14.4489 15.4255 14.204C15.8357 13.959 15.9999 13.5509 15.9999 13.0611C15.9999 11.0203 14.2768 9.30603 12.2255 9.30603Z" />
      <path d="M5.90764 7.59176C6.89228 7.59176 7.71276 7.26528 8.45123 6.61224C9.27171 5.95915 9.68195 4.89793 9.68195 3.83671C9.68195 3.02039 9.43579 2.20407 8.94355 1.63265C8.28707 0.65306 7.22044 0 5.90764 0C3.85637 0 2.1333 1.71428 2.1333 3.75508C2.1333 5.87752 3.85637 7.59176 5.90764 7.59176Z" />
      <path d="M8.12299 9.55108C7.87691 9.55108 7.71276 9.46948 7.4666 9.46948H4.3487C1.96922 9.46948 0 11.4287 0 13.7959C0 14.3674 0.246151 14.7755 0.656404 15.1021C1.31282 15.5102 2.87178 16 5.90765 16C8.94355 16 10.4204 15.5102 11.1589 15.1021C11.1589 15.1021 11.241 15.0205 11.323 15.0205C11.6512 14.7755 11.8974 14.3674 11.8974 13.7959C11.8153 11.6736 10.2563 9.87764 8.12299 9.55108Z" />
    </svg>
  );
};
