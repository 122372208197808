type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const EditFormIcon = ({ classNames, width = 22, height = 22 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 22 22' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M1.72343 3.55546C2.23915 3.03973 2.93862 2.75 3.66797 2.75H10.0846C10.5909 2.75 11.0013 3.16041 11.0013 3.66667C11.0013 4.17293 10.5909 4.58333 10.0846 4.58333H3.66797C3.42485 4.58333 3.1917 4.67991 3.01979 4.85182C2.84788 5.02373 2.7513 5.25688 2.7513 5.5V18.3333C2.7513 18.5764 2.84788 18.8096 3.01979 18.9815C3.1917 19.1534 3.42485 19.25 3.66797 19.25H16.5013C16.7444 19.25 16.9776 19.1534 17.1495 18.9815C17.3214 18.8096 17.418 18.5764 17.418 18.3333V11.9167C17.418 11.4104 17.8284 11 18.3346 11C18.8409 11 19.2513 11.4104 19.2513 11.9167V18.3333C19.2513 19.0627 18.9616 19.7622 18.4458 20.2779C17.9301 20.7936 17.2306 21.0833 16.5013 21.0833H3.66797C2.93862 21.0833 2.23915 20.7936 1.72343 20.2779C1.2077 19.7621 0.917969 19.0627 0.917969 18.3333V5.5C0.917969 4.77065 1.2077 4.07118 1.72343 3.55546Z"
            fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M18.3347 2.639C18.062 2.639 17.8006 2.74729 17.6078 2.94006L9.07904 11.4689L8.59449 13.407L10.5327 12.9225L19.0615 4.39369C19.2542 4.20093 19.3625 3.93948 19.3625 3.66687C19.3625 3.39426 19.2542 3.13282 19.0615 2.94006C18.8687 2.74729 18.6073 2.639 18.3347 2.639ZM16.3115 1.64369C16.8481 1.10711 17.5758 0.805664 18.3347 0.805664C19.0935 0.805664 19.8213 1.10711 20.3578 1.64369C20.8944 2.18027 21.1959 2.90803 21.1959 3.66687C21.1959 4.42571 20.8944 5.15347 20.3578 5.69006L11.6495 14.3984C11.532 14.5159 11.3848 14.5992 11.2236 14.6395L7.55698 15.5562C7.2446 15.6343 6.91416 15.5427 6.68647 15.3151C6.45879 15.0874 6.36726 14.7569 6.44536 14.4446L7.36202 10.7779C7.40232 10.6167 7.48566 10.4695 7.60314 10.352L16.3115 1.64369Z"
            fill="currentColor" />
    </svg>

  );
};
