type IconProps = {
  classNames?: string;
  width?: number;
  height?: number;
}

export const TrashIcon = ({ classNames = 'fill-gray-v1', width = 13, height = 16 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 13 16' xmlns='http://www.w3.org/2000/svg' className={classNames}>
      <path
        d='M10.8991 2.87662L10.4696 14.4725C10.4623 14.6715 10.287 14.8401 10.0871 14.8401H2.66683C2.46716 14.8401 2.29166 14.6709 2.28431 14.4725L1.85483 2.87662C1.84298 2.55668 1.57401 2.30691 1.25406 2.31876C0.934117 2.33061 0.684356 2.59959 0.696206 2.91953L1.12568 14.5154C1.15611 15.3369 1.84342 15.9995 2.66683 15.9995H10.0871C10.9101 15.9995 11.5978 15.3382 11.6283 14.5154L12.0577 2.91953C12.0696 2.59959 11.8198 2.33061 11.4999 2.31876C11.1799 2.30691 10.911 2.55668 10.8991 2.87662Z' />
      <path
        d='M5.91309 5.21767V13.1017C5.91309 13.3579 6.12072 13.5655 6.37685 13.5655C6.63299 13.5655 6.84062 13.3579 6.84062 13.1017V5.21767C6.84062 4.96154 6.63299 4.75391 6.37685 4.75391C6.12072 4.75391 5.91309 4.96154 5.91309 5.21767Z' />
      <path
        d='M3.36251 5.23131L3.59439 13.1154C3.60192 13.3714 3.81557 13.5728 4.0716 13.5653C4.32762 13.5578 4.52906 13.3441 4.52153 13.0881L4.28964 5.20404C4.28211 4.94802 4.06846 4.74658 3.81244 4.75411C3.55642 4.76164 3.35498 4.97529 3.36251 5.23131Z' />
      <path
        d='M8.46402 5.20404L8.23214 13.0881C8.22461 13.3441 8.42605 13.5578 8.68207 13.5653C8.93809 13.5728 9.15174 13.3714 9.15927 13.1154L9.39116 5.23131C9.39869 4.97529 9.19725 4.76164 8.94122 4.75411C8.6852 4.74658 8.47155 4.94802 8.46402 5.20404Z'
      />
      <path
        d='M0.57971 3.13012H12.1739C12.4941 3.13012 12.7536 2.87058 12.7536 2.55041C12.7536 2.23025 12.4941 1.9707 12.1739 1.9707H0.57971C0.259545 1.9707 0 2.23025 0 2.55041C0 2.87058 0.259545 3.13012 0.57971 3.13012Z'
      />
      <path
        d='M4.61579 2.36216L4.85718 1.51728C4.90888 1.33633 5.14345 1.15942 5.33177 1.15942H7.42267C7.61111 1.15942 7.84552 1.33618 7.89726 1.51728L8.13866 2.36216L9.25347 2.04364L9.01208 1.19877C8.8181 0.519845 8.1287 0 7.42267 0H5.33177C4.62583 0 3.93629 0.520032 3.74237 1.19877L3.50098 2.04364L4.61579 2.36216Z'
      />
    </svg>
  );
};
