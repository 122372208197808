type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const IconWhatsapp = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 16 16' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <g clip-path="url(#clip0_823_3511)">
        <path
          d="M7.20417 0.0218105C5.71667 0.181186 4.35417 0.718685 3.13854 1.62806C2.74167 1.92494 1.92604 2.74056 1.62917 3.13743C0.816668 4.22181 0.316668 5.38118 0.0916675 6.69993C0.00729251 7.18744 -0.0239575 8.31243 0.0322925 8.82181C0.176043 10.1593 0.613543 11.3687 1.34479 12.4531L1.50729 12.6937L1.00417 14.1812C0.729168 14.9999 0.507293 15.6718 0.510418 15.6749C0.513543 15.6812 1.19167 15.4687 2.01667 15.2031C2.84167 14.9374 3.54479 14.7187 3.57604 14.7187C3.60729 14.7187 3.70729 14.7624 3.79479 14.8187C4.79479 15.4437 5.95104 15.8374 7.17917 15.9687C7.68854 16.0249 8.81354 15.9937 9.30104 15.9093C10.6198 15.6843 11.7823 15.1843 12.8635 14.3718C13.2604 14.0749 14.076 13.2593 14.3729 12.8624C15.1979 11.7593 15.701 10.5749 15.9292 9.20306C16.0104 8.70931 16.0104 7.29056 15.9292 6.79681C15.701 5.42494 15.1979 4.24056 14.3729 3.13743C14.076 2.74056 13.2604 1.92494 12.8635 1.62806C11.7823 0.818686 10.626 0.318686 9.30104 0.09056C8.99792 0.0374355 8.76354 0.0218105 8.12604 0.0155602C7.68854 0.00931072 7.27292 0.012435 7.20417 0.0218105ZM5.48542 3.73118C5.56667 3.76556 5.61354 3.82181 5.68542 3.96556C5.86667 4.32181 6.53229 5.95931 6.53229 6.04681C6.53229 6.19368 6.41042 6.38743 6.10729 6.73431C5.94792 6.91243 5.80104 7.09056 5.78229 7.12181C5.69792 7.28119 5.77917 7.49368 6.09167 7.93744C6.77604 8.90931 7.55729 9.59056 8.54167 10.0624C9.32292 10.4406 9.41979 10.4156 9.92292 9.71868C10.2854 9.21556 10.4292 9.09681 10.6385 9.14368C10.851 9.19056 12.6729 10.0781 12.751 10.1749C12.8635 10.3093 12.7635 11.0468 12.5823 11.3999C12.3229 11.9124 11.5198 12.3843 10.7885 12.4531C10.0292 12.5281 8.21979 11.8968 7.14792 11.1843C6.06354 10.4624 4.94792 9.28118 4.07917 7.94056C3.64167 7.26556 3.37604 6.48431 3.37604 5.87494C3.37604 5.21869 3.57917 4.66244 4.00104 4.16556C4.19479 3.93118 4.37604 3.80306 4.60417 3.73431C4.78542 3.67494 5.34792 3.67494 5.48542 3.73118Z"
          fill="currentColor" />
      </g>
    </svg>
  );
};
