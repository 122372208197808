type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const LandingEllipse = ({ classNames, width = 194, height = 48 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox="0 0 194 48" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.4"
            d="M192.981 25.1433C192.951 28.0951 190.447 31.0322 185.55 33.7817C180.691 36.51 173.642 38.9566 164.897 40.9889C147.417 45.0516 123.275 47.4336 96.6206 47.1617C69.9658 46.8899 45.8776 44.016 28.4839 39.5977C19.7831 37.3875 12.7853 34.7977 7.9828 31.9708C3.14306 29.122 0.698968 26.1344 0.729072 23.1826C0.759176 20.2308 3.26369 17.2936 8.16053 14.5441C13.0196 11.8158 20.0688 9.36927 28.8129 7.33699C46.2931 3.27426 70.4349 0.892286 97.0897 1.16412C123.745 1.43596 147.833 4.30981 165.226 8.7282C173.927 10.9384 180.925 13.5282 185.728 16.355C190.567 19.2038 193.011 22.1915 192.981 25.1433Z"
            stroke="currentColor" />
    </svg>

  );
};
