type IconProps = {
  classNames?: string
  width?: string
  height?: string
}

export const LocationIcon = (
  {
    classNames = 'fill-gray-v1',
    width = '16',
    height = '16'
  }: IconProps
) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd'
            d='M5.96846 4.09745C6.37144 4.09745 6.76537 4.21695 7.10044 4.44083C7.43551 4.66472 7.69666 4.98293 7.85087 5.35524C8.00509 5.72755 8.04544 6.13722 7.96682 6.53246C7.8882 6.9277 7.69415 7.29075 7.4092 7.5757C7.12425 7.86066 6.7612 8.05471 6.36596 8.13333C5.97072 8.21195 5.56104 8.1716 5.18873 8.01738C4.81643 7.86317 4.49821 7.60201 4.27433 7.26695C4.05044 6.93188 3.93094 6.53795 3.93094 6.13496C3.93155 5.59477 4.14642 5.07688 4.52839 4.6949C4.91037 4.31292 5.42826 4.09806 5.96846 4.09745ZM5.96846 8.92252C6.51978 8.92252 7.05873 8.75904 7.51714 8.45273C7.97555 8.14643 8.33284 7.71108 8.54383 7.20172C8.75481 6.69236 8.81001 6.13187 8.70245 5.59114C8.5949 5.05041 8.32941 4.55371 7.93956 4.16386C7.54971 3.77401 7.05302 3.50853 6.51228 3.40097C5.97155 3.29341 5.41106 3.34861 4.9017 3.55959C4.39234 3.77058 3.95699 4.12787 3.65069 4.58628C3.34438 5.04469 3.1809 5.58364 3.1809 6.13496C3.18184 6.87398 3.47583 7.58246 3.99839 8.10503C4.52096 8.62759 5.22944 8.92158 5.96846 8.92252ZM2.18338 2.31114C-0.0372413 4.53176 0.60116 7.73461 2.21983 9.92359L5.96846 14.9944L9.71708 9.92341C11.3349 7.73443 11.9749 4.53158 9.75264 2.31096C8.74799 1.30857 7.38676 0.745627 5.96757 0.745627C4.54838 0.745627 3.18714 1.30857 2.1825 2.31096L2.18338 2.31114ZM1.61663 10.3691L5.66623 15.848C5.70112 15.8952 5.74656 15.9335 5.79893 15.9599C5.85129 15.9863 5.90911 16 5.96775 16C6.02638 16 6.0842 15.9863 6.13656 15.9599C6.18893 15.9335 6.23437 15.8952 6.26926 15.848L10.3203 10.3691C11.3132 9.02599 11.8847 7.47132 11.9311 5.9931C11.9665 5.21478 11.8382 4.43771 11.5544 3.7121C11.2707 2.98649 10.8378 2.3285 10.2838 1.78065C9.13719 0.640186 7.5857 0 5.96846 0C4.35121 0 2.79973 0.640186 1.65307 1.78065C1.09899 2.3286 0.66607 2.98674 0.382315 3.71251C0.0985597 4.43828 -0.0297165 5.21553 0.00578113 5.99399C0.0516479 7.47185 0.623738 9.02599 1.61663 10.3691Z'
      />
    </svg>
  );
};
