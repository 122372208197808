type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const ChevronDown = ({ classNames, width = 16, height = 16 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 20 20' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M2.05862 5.80666C2.11667 5.74846 2.18564 5.70228 2.26158 5.67077C2.33751 5.63926 2.41891 5.62305 2.50112 5.62305C2.58333 5.62305 2.66473 5.63926 2.74066 5.67077C2.81659 5.70228 2.88556 5.74846 2.94362 5.80666L10.0011 12.8654L17.0586 5.80666C17.1167 5.74855 17.1857 5.70246 17.2616 5.67101C17.3376 5.63956 17.4189 5.62337 17.5011 5.62337C17.5833 5.62337 17.6647 5.63956 17.7406 5.67101C17.8165 5.70246 17.8855 5.74855 17.9436 5.80666C18.0017 5.86477 18.0478 5.93376 18.0793 6.00968C18.1107 6.08561 18.1269 6.16698 18.1269 6.24916C18.1269 6.33134 18.1107 6.41272 18.0793 6.48864C18.0478 6.56457 18.0017 6.63355 17.9436 6.69166L10.4436 14.1917C10.3856 14.2499 10.3166 14.296 10.2407 14.3276C10.1647 14.3591 10.0833 14.3753 10.0011 14.3753C9.91891 14.3753 9.83751 14.3591 9.76158 14.3276C9.68564 14.296 9.61667 14.2499 9.55862 14.1917L2.05862 6.69166C2.00041 6.63361 1.95423 6.56464 1.92273 6.48871C1.89122 6.41277 1.875 6.33137 1.875 6.24916C1.875 6.16695 1.89122 6.08555 1.92273 6.00962C1.95423 5.93369 2.00041 5.86472 2.05862 5.80666Z"
            fill="#8895AA" stroke="currentColor" strokeWidth="0.5" />
    </svg>

  );
};
