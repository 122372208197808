type Props = {
  classNames?: string
  width?: number
  height?: number
}

export const IconTwitter = ({ classNames, width = 24, height = 24 }: Props) => {
  return (
    <svg width={width} height={height} className={classNames} viewBox='0 0 24 25' fill='none'
         xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.539 23.0001C16.596 23.0001 21.5505 15.4956 21.5505 8.99906C21.5505 8.78906 21.5505 8.57606 21.5415 8.36606C22.5061 7.66779 23.3386 6.80329 24 5.81306C23.099 6.21034 22.1441 6.47217 21.1665 6.59006C22.1963 5.97452 22.9676 5.00602 23.337 3.86456C22.3695 4.43775 21.3105 4.84012 20.2065 5.05406C19.4643 4.2636 18.4821 3.73994 17.4121 3.5642C16.3421 3.38846 15.2441 3.57045 14.288 4.08197C13.3319 4.59349 12.5712 5.40601 12.1237 6.39365C11.6761 7.3813 11.5668 8.48896 11.8125 9.54506C9.85461 9.44689 7.93922 8.93826 6.19056 8.05218C4.4419 7.16609 2.89903 5.92233 1.662 4.40156C1.03401 5.48619 0.842361 6.76916 1.12597 7.98996C1.40958 9.21076 2.14718 10.2779 3.189 10.9746C2.40831 10.948 1.64478 10.7384 0.96 10.3626V10.4301C0.961346 11.5663 1.35496 12.6672 2.07431 13.5467C2.79366 14.4262 3.79462 15.0304 4.908 15.2571C4.48539 15.3735 4.04884 15.4315 3.6105 15.4296C3.30148 15.4305 2.99307 15.4019 2.6895 15.3441C3.00418 16.3221 3.61691 17.1773 4.44187 17.7898C5.26683 18.4022 6.2627 18.7413 7.29 18.7596C5.54483 20.1303 3.3891 20.8737 1.17 20.8701C0.778981 20.8717 0.388235 20.8492 0 20.8026C2.25227 22.2385 4.86795 23.0009 7.539 23.0001Z'
        fill='currentColor' />
    </svg>

  );
};
