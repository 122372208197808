export const IconPlus = ({ classNames }: { classNames?: string }) => {
  return (
    <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'
         className={classNames}>
      <path
        d='M11.5163 5.51646H6.48335V0.483543C6.48335 0.216434 6.26692 0 5.99981 0C5.7327 0 5.51626 0.216434 5.51626 0.483543V5.51646H0.483543C0.216434 5.51646 0 5.73289 0 6C0 6.26711 0.216434 6.48354 0.483543 6.48354H5.51646V11.5165C5.51646 11.7836 5.73289 12 6 12C6.26711 12 6.48354 11.7836 6.48354 11.5165V6.48354H11.5165C11.7836 6.48354 12 6.26711 12 6C12 5.73289 11.7834 5.51646 11.5163 5.51646Z' />
    </svg>

  );
};
